import { Heading1, Heading2 } from '@ovotech/nebula';
import styled from 'styled-components';
import Lightbulb from '../images/lightbulb.jpg';
import React from 'react';

export const Container = styled.div`
 width: 100%;
 height: 100vh;
 background: url(${Lightbulb}) 100% 100% no-repeat #78c34d;
 background-size: 100% auto;
}
`;

export const StyledH1 = styled(props => <Heading1 {...props} />)`
  color: white;
`;

export const StyledH2 = styled(props => <Heading2 {...props} />)`
  color: white;
`;

import '@ovotech/ovoenergy-theme/dist/global.css';
import { Margin } from '@ovotech/nebula';
import React, { useState, useEffect } from 'react';
import { HeaderLogo } from '../components/Header/HeaderLogo';

import { Seo } from '../components/Seo';

import { ThemeProvider } from 'styled-components';
import { createTheme } from '@ovotech/nebula';
import { AdditionalConfig, themeConfig } from '@ovotech/ovoenergy-theme';
import { Container, StyledH1, StyledH2 } from '../components/404.styled';
import { Footer } from '../components/Footer/Footer';

const theme = createTheme<AdditionalConfig>(themeConfig);

function NotFoundPage() {
  const browser = typeof window !== 'undefined' && window;
  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, []);

  if (!isMount) {
    return null;
  }

  return (
    <>
      {browser && (
        <ThemeProvider theme={theme}>
          <Seo title="Leasing Error" />
          <Margin left={8}>
            <HeaderLogo />
          </Margin>
          <Container>
            <Margin left={8}>
              <StyledH1>Oops!</StyledH1>
              <StyledH2>Looks like we broke something</StyledH2>
            </Margin>
          </Container>
          <Footer />
        </ThemeProvider>
      )}
    </>
  );
}

export default NotFoundPage;
